import React from 'react'
import { graphql, navigate } from 'gatsby'

import { LayoutBackground } from '../components/shared'
import { Layout } from '../components'
import { BioEmployeeForm } from '../components/forms/BioEmployeeForm'
import * as styles from '../css/organism/bioEmployeeCardPage.module.scss'

const BioEmployeeCardPage = ({ data, pageContext }) => {
  if (!data.employeeCards.nodes[0]) {
    navigate('/')
    return null
  }

  const { title, subline, subText, sendMessage, errorMessage, employeeForm } =
    data.employeeCards.nodes[0]
  const languages = { pageContext }
  const layoutBackgroundHeaderComponent = (
    <div className={styles.layoutHeader}>
      <h1 className="bioHeading1">{title}</h1>
      <div className="bioSubline1">{subline}</div>
    </div>
  )

  return (
    <Layout
      standardPaddingTopFromNavbarToMain
      reducedFooter
      translations={languages}>
      <LayoutBackground headerComponent={layoutBackgroundHeaderComponent}>
        <div className={styles.bioEmployeeCardPage}>
          <p className="bioBody">{subText}</p>
          <BioEmployeeForm
            formData={employeeForm}
            sendMessage={sendMessage}
            errorMessage={errorMessage}
          />
        </div>
      </LayoutBackground>
    </Layout>
  )
}

export default BioEmployeeCardPage

export const pageQuery = graphql`
  query ($language: String! = "de_de") {
    employeeCards: allSanityEmployeeCardPage(
      filter: {
        i18n_lang: { eq: $language }
        isNotAvailableInCountry: { ne: true }
      }
    ) {
      nodes {
        title
        subline
        subText
        errorMessage
        sendMessage
        employeeForm {
          subject {
            name
            options
            placeholder
            required
            initialValue
          }
          firstName {
            name
            placeholder
            required
            type
            initialValue
          }
          lastName {
            name
            placeholder
            required
            type
            initialValue
          }
          cardNumber {
            name
            placeholder
            type
            initialValue
          }
          membership {
            name
            placeholder
            type
            initialValue
          }
          privacy {
            name
            _rawLabel
            required
            initialValue
          }
        }
      }
    }
  }
`
